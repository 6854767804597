<template>
    <div class="btn-controls">
        <a href="javascript:void(0)" class="btn-control" @click="vote('up')">
            <img src="/SVG/thumb_up_active.svg" v-if="isAnswerLiked">
            <img src="/SVG/13.svg" alt="Like" v-else>
            <span class="c-black">
                Gute Antwort {{current_likes}}
            </span>
        </a>

        <a href="javascript:void(0)" class="btn-control" @click="vote('down')">
            <img src="/SVG/thumb_down_active.svg" v-if="isAnswerDisliked">
            <img src="/SVG/13-2.svg" alt="Dislike" v-else>
            <span class="c-black">
                {{current_dislikes}}
            </span>
        </a>
    </div>
</template>

<script>
    const axios = require('axios');
    export default {
        name: 'cvotes',
        props:['id','csrf','slug','likes','dislikes', 'liked', 'disliked'],
        data: function () {
            return {
                last:'',
                current_likes: 0,
                current_dislikes: 0,
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                isAnswerLiked: false,
                isAnswerDisliked: false
            }
        },
        mounted() {
            this.current_likes = +this.likes;
            this.current_dislikes = +this.dislikes;

            this.isAnswerLiked = this.liked;
            this.isAnswerDisliked = this.disliked;
        },
        methods: {

            vote(kind) {
                let self = this;
                this.last = kind;
                axios.get(`/comments/${self.id}/vote`,{params:{vote:kind}, headers: self.headers})
                    .then(function (response) {
                        self.isAnswerLiked = response.data.likes > self.current_likes;
                        self.isAnswerDisliked = response.data.dislikes > self.current_dislikes;

                        self.current_likes = response.data.likes;
                        self.current_dislikes = response.data.dislikes;
                    });
            }
        }
    }
</script>
