(function () {
    window.addEventListener('DOMContentLoaded', function () {
        let avatarReminderEl = document.getElementById('add-avatar-reminder');

        if (avatarReminderEl) {
            let avatarReminderCloser = avatarReminderEl.querySelector('.add-avatar-reminder__close-icon');

            if (window.location.pathname != '/profile') {
                setTimeout(function () {
                    avatarReminderEl.style.display = 'block';
                }, 300);
            }

            avatarReminderCloser.addEventListener('click', function () {
                avatarReminderEl.remove();
            }, { once:true });
        }
    });
}).call(this);
