<template>
    <div class="communities form-group">
        <label>Community</label>
        <multiselect
                v-model="value"
                :options="options"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                placeholder="Geben Sie 1 bis 5 Community-Namen"
                select-label=""
                deselect-label=""
                :max="5"
                label="name"
                track-by="name"
                @input="logIt"
        >
            <template v-slot:maxElements><span>&nbsp;</span></template>
        </multiselect>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    export default {
        components: { Multiselect },
        name: 'communities',
        props: ['current'],
        data: function () {
            return {
                value: null,
                options: [
                    {name:'Auto, Rad und Boot',id:'auto-rad-und-boot'},
                    {name:'Beauty und Gesundheit',id:'beauty-und-gesundheit'},
                    {name:'Beruf und Karriere',id:'beruf-und-karriere'},
                    {name:'Computer, Elektronik, Internet',id:'computer-elektronik-internet'},
                    {name:'Computer- und Videospiele',id:'computer-und-videospiele'},
                    {name:'Essen und Kochen',id:'essen-und-kochen'},
                    {name:'Familie und Beziehung',id:'familie-und-beziehung'},
                    {name:'Gesellschaft, Geschichte, Politik',id:'gesellschaft-geschichte-politik'},
                    {name:'Literatur, Kunst und Kultur',id:'literatur-kunst-und-kultur'},
                    {name:'Mode und Style',id:'mode-und-style'},
                    {name:'Naturwissenschaftliche Erkenntnisse',id:'naturwissenschaftliche-erkenntnisse'},
                    {name:'Pflanzen und Gärtnerei',id:'pflanzen-und-gartnerei'},
                    {name:'Reisen und Geographie',id:'reisen-und-geographie'},
                    {name:'Sport und Sportarten',id:'sport-und-sportarten'},
                    {name:'Sprachen und Geisteswissenschaften',id:'sprachen-und-geisteswissenschaften'},
                    {name:'Studienbeihilfe',id:'studienbeihilfe'},
                    {name:'Tiere und Haustiere',id:'tiere-und-haustiere'},
                    {name:'Unterhaltung und Freizeit',id:'unterhaltung-und-freizeit'},
                    {name:'Wirtschaft und Recht',id:'wirtschaft-und-recht'},
                    {name:'Wohnungen und Häuser',id:'wohnungen-und-hauser'}
                ]
            }
        },
        mounted() {
            this.value = gon.current_categories;
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
    .multiselect__tag {
        background-color: #2c6e83!important;
    }

    .multiselect__option--highlight {
        background:  #2c6e83!important;
    }

    .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
        background: #3a86a2 !important;
    }
    .multiselect__tag-icon:after {
        content: "\D7";
        color: #386e8a;
        font-size: 14px;
    }
</style>