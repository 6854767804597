<template>
    <div class="wrapper">
        <div class="user-themes">
            <div class="left">
                <div class="f-s-22 slab">Willkommen auf Deiner persönlichen Startseite!</div>
                <div class="d-block d-sm-none m-b-20">
                    <img src="/img/themes.png" class="img-man" alt="themes">
                </div>

                <p>Du kannst Themen folgen, um Beiträgen angezeigt zu bekommen, die Dich interessieren.</p>
                <span class="btn btn-primary bg-meinung" @click="toggleOpened">Themen hinzufügen</span>
            </div>
            <div class="right d-none d-sm-block">
                <img src="/img/themes.png" class="img-man" alt="themes">
            </div>
        </div>
        <div v-show="opened" id="blocker">
            <div id="block-window">
                <button class="themes-closer base-icon-btn" @click="toggleOpened">
                    <i class="fas fa-times"></i>
                </button>
                <div class="communities form-group">
                    <label>Deine Themen editieren</label>

                    <input v-model="search_theme"  v-debounce:300ms="searchThemes" type="text" class="themes-search-input" placeholder="Suchen"/>
                    <div class="themes-suggestions">
                        <div class="theme-suggestion" v-for="(suggestion, index) in suggestions" :key="index"  @click="addTheme(index)">
                            <span class="theme-name">{{suggestion.name}}</span>
                            <svg class="suggestion-control" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/><path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/></svg>
                        </div>
                    </div>

                    <div class="themes" v-if="selected_themes.length > 0">
                        <div v-for="(theme,index) in selected_themes" :key="index" class="theme">
                            {{theme.name}}
                            <span class="closer" @click="removeTheme(index)">&times;</span>
                        </div>
                    </div>
                </div>
                <span class="btn btn-primary bg-meinung" @click="save">
                    Speichern
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    // import themes from "../question2/themes";

    const axios = require('axios');
    export default {
        name: 'themes-selector',
        props:['csrf'],
        components: {},
        data: function () {
            return {
                opened: false,
                suggestions: [],
                selected_themes: [],
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                search_theme:'',
            }
        },
        mounted() {
            this.themes = gon.themes;
            this.selected_themes = gon.current_themes;
        },
        methods: {
            toggleOpened() {
              if (document.body.id === 'mobile-body') {
                  window.location.href = '/tags'
              } else {
                  this.opened = !this.opened
              }
            },
            searchThemes() {
                if (this.search_theme.length > 0) {
                    let self = this;
                    axios.get('/tags/lookup',{params:{q:self.search_theme,w:self.current_slugs,skip_new:'yes'}, headers: self.headers})
                        .then(function (response) {
                            self.suggestions = response.data.themes;
                        })
                } else {
                    this.suggestions = []
                }

            },
            addTheme(index) {
                //this.themes.push(this.suggestions[index]);
                this.selected_themes.push(this.suggestions[index]);
                this.suggestions = [];
                this.search_theme = '';
                //this.opened = false;
            },
            removeTheme(index) {
                this.selected_themes.splice(index,1);
            },
            save() {
                let self = this;
                axios.post(`/update_themes`,{themes: this.selected_themes}, {headers: this.headers})
                    .then(function (response) {
                        location.reload();
                    })
            }
        },
        computed: {
            current_slugs: function() {
                let arr = [];
                this.selected_themes.forEach(function (theme) {
                    arr.push(theme.slug);
                });
                return arr.join(',')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-themes {
        border-radius: 6px;
        border: 1px solid #e9ecef;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    .img-man {
        width:185px;
        height:195px;
    }

    .f-s-22 {
        margin-bottom: 25px;
    }

    .btn {
        cursor: pointer;
    }

    #blocker {
        position: fixed;
        top:0;
        left:0;
        z-index: 9999998;
        width: 100%;
        height: 100%;
        outline:0;
        display: flex;
        align-items: flex-start;
        background-color: rgba(0,0,0,0.8);
        justify-content: center;
    }

    #block-window {
        margin-top: 50px;
        background-color: white;
        width: 100%;
        max-width: 600px;
        padding: 25px;
        border-radius:6px;
        position: relative;
    }

    label {
        font-family: 'Roboto Slab', serif;
        margin-bottom: 30px;
        font-size: 18px;
    }

    .themes-closer {
        position: absolute;
        right: 10px;
        top: 12px;
    }

    .themes {
        margin-top: 0;

        .theme {
            margin: 0 10px 8px 0;
        }
    }

    @media (max-width: 991px) {
        .user-themes {
            .left {
                text-align: center;
            }
        }
    }

</style>