<template>
    <div v-show="show" id="question-screen" v-on:mousedown.self="close()">
            <div class="question-window" v-resize="onResize">
            <div class="question-content" v-bind:class="colorClass" v-bind:style="question.img !== null ? `background-image:url(${question.img})` : '' ">
                <div class="question-window-title">
                    <div class="links">
                        <a v-bind:class="question.meinung ? 'current' : ''" href="javascript:void(0)" @click="setMeinung(true)">Meinung</a>
                        <a v-bind:class="question.meinung ? '' : 'current'" href="javascript:void(0)" @click="setMeinung(false)">Frage</a>
                    </div>
                    <span style="cursor: pointer" @click="close"><i class="fas fa-times"></i></span>
                </div>

                <textarea
                        autofocus
                        class="question-body"
                        v-bind:placeholder="question.meinung ? 'Hier kannst du Meinungen Teilen oder entdecken.' : 'Hier kannst du Fragen stellen, und vom Wissen anderer Nutzer in allen denkbaren Bereiche profitieren'"
                        ref="qTitle"
                        v-model="question.title"
                />
            </div>
            <div style="padding: 0 20px;margin-bottom:10px;">
                <textarea-autosize
                        v-show="show_content"
                        class="question-body q-content"
                        rows="2"
                        placeholder="Optional: Beschreibung eingeben"
                        ref="qContent"
                        v-model="question.content"
                />
            </div>
            <div class="themes" v-if="question.themes.length > 0">
                <div v-for="(theme,index) in question.themes" class="theme">
                    {{theme.name}}
                    <span class="closer" @click="removeTheme(index)">&times;</span>
                </div>
            </div>
            <div class="question-controls">

                <div class="communities form-group">
                    <label>Community</label>
                    <multiselect
                            v-model="question.communities"
                            :options="options"
                            :multiple="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :preserve-search="true"
                            placeholder="Geben Sie 1 bis 5 Community-Namen"
                            select-label=""
                            deselect-label=""
                            :max="5"
                            label="name"
                            track-by="name"
                    >
                        <template v-slot:maxElements><span>Текст о том что превышен лимит</span></template>
                    </multiselect>
                </div>

                <div class="color-buttons">
                    <div v-for="(color, index) in colors" :key="index" v-bind:class="`control-button ${color}`" @click="colorQuestion(color)">
                        <img v-if="color === question.color" src="/img/check.png" alt="Check icon">
                        <img v-else src="/img/lines.png" alt="Line icon">
                    </div>
                </div>

                <div class="more-buttons">
                    <themes :csrf="csrf" :current_themes="question.themes" @get-theme="getTheme"/>
                    <div class="grey-button" @click="question.img === null ? clickUploader() : clearUploader()">
                        <img src="/img/question-img.png" alt="Löschen">
                        <span v-if="question.img !== null">Löschen</span>
                        <span v-else>Titelbild</span>
                    </div>
                    <div class="grey-button" @click="toggleContent" v-bind:class="show_content ? 'dark' : ''">
                        <img src="/img/question-details.png" alt="Details">
                        Details
                    </div>
                    <div class="grey-button" @click="toggleSurvey" v-bind:class="show_survey ? 'dark' : ''">
                        <img src="/img/votes.png" alt="Umfragen">
                        Umfragen
                    </div>

                </div>

                <div class="question-survey" v-if="show_survey">
                    <div v-for="(variant, index) in question.survey" class="form-group">
                        <label :for="'variant_'+index">
                            <span v-bind:class="('deleted' in variant) ? 'deleted' : ''">Antwort {{index+1}}</span>
                            <small v-if="(question.survey.length > 2) && (!('deleted' in variant))" class="false-link" v-on:click="removeVariant(index)">Löschen</small>
                            <small v-if="('deleted' in variant)" class="false-link c-green" v-on:click="restoreVariant(index)">Restore</small>
                        </label>
                        <input  type="text" v-model="variant.txt" class="form-control" :id="'variant_'+index" maxlength="70" required>
                    </div>
                    <div v-if="question.survey.length > 0">
                    <span v-if="question.survey.length < 7" v-on:click="addVariant()" class="false-link">
                        <svg class="suggestion-control" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/><path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/></svg>
                        Weitere Antwort
                    </span>
                    </div>
                </div>

                <div v-show="false" class="img-uploader">
                    <vue-base64-file-upload
                            placeholder=""
                            ref="uploader"
                            class="v1"
                            accept="image/png,image/jpeg"
                            image-class="w-100"
                            input-class="v1-input"
                            :max-size="2"
                            :disable-preview = false
                            @load="onImageUpload" />
                </div>
            </div>
            <div class="question-tags">
                <p>Bitte beachte unsere <a href="/guide" target="_blank">Richtlinien</a>, bevor Du Deinen Beitrag abschickst</p>
                <span @click="save" class="btn btn-primary">
                    {{saving ? '...' : 'Speichern'}}
                </span>
            </div>
        </div>
    </div>

</template>

<script>
    // import VueTagsInput from "@johmun/vue-tags-input";

    const axios = require('axios');
    import communities from './communities'
    import themes from './themes'
    import tags from './tags'
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import Multiselect from 'vue-multiselect';
    import resize from 'vue-resize-directive'

    export default {
        name: 'question',
        directives: {resize},
        components: {themes,communities,VueBase64FileUpload,tags,Multiselect},
        props:['id','csrf'],
        data: function () {
            return {
                show: true,
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                colors: ['orange','green','teal','dirt','purple','cloud','red'],
                show_content: false,
                show_survey: false,
                saving: false,
                options: [
                    {name:'Auto, Rad und Boot',id:'auto-rad-und-boot'},
                    {name:'Beauty und Gesundheit',id:'beauty-und-gesundheit'},
                    {name:'Beruf und Karriere',id:'beruf-und-karriere'},
                    {name:'Computer, Elektronik, Internet',id:'computer-elektronik-internet'},
                    {name:'Computer- und Videospiele',id:'computer-und-videospiele'},
                    {name:'Essen und Kochen',id:'essen-und-kochen'},
                    {name:'Familie und Beziehung',id:'familie-und-beziehung'},
                    {name:'Gesellschaft, Geschichte, Politik',id:'gesellschaft-geschichte-politik'},
                    {name:'Literatur, Kunst und Kultur',id:'literatur-kunst-und-kultur'},
                    {name:'Mode und Style',id:'mode-und-style'},
                    {name:'Naturwissenschaftliche Erkenntnisse',id:'naturwissenschaftliche-erkenntnisse'},
                    {name:'Pflanzen und Gärtnerei',id:'pflanzen-und-gartnerei'},
                    {name:'Reisen und Geographie',id:'reisen-und-geographie'},
                    {name:'Sport und Sportarten',id:'sport-und-sportarten'},
                    {name:'Sprachen und Geisteswissenschaften',id:'sprachen-und-geisteswissenschaften'},
                    {name:'Studienbeihilfe',id:'studienbeihilfe'},
                    {name:'Tiere und Haustiere',id:'tiere-und-haustiere'},
                    {name:'Unterhaltung und Freizeit',id:'unterhaltung-und-freizeit'},
                    {name:'Wirtschaft und Recht',id:'wirtschaft-und-recht'},
                    {name:'Wohnungen und Häuser',id:'wohnungen-und-hauser'}
                ],
                tag:'',
                question: {
                    communities:[],
                    survey:[{txt:''},{txt:''}],
                    title:'',
                    color: null,
                    content:null,
                    img: null,
                    themes:[],
                    meinung: true
                }
            }
        },
        mounted: function() {

        },
        methods: {
            toggleSurvey() {
                this.show_survey = !this.show_survey
            },
            setMeinung(result) {
              this.question.meinung = result;
            },
            getTheme(theme) {
                this.question.themes.push(theme)

            },
            removeTheme(index) {
                this.question.themes.splice(index,1);
            },
            colorQuestion(color) {
                if (this.question.color === color) {
                    this.question.color = null
                } else {
                    this.question.color = color
                }
            },
            toggleContent() {
              this.show_content = !this.show_content
            },
            onImageUpload(dataUri) {
                //console.log(dataUri);
                this.question.img = dataUri
            },
            clickUploader() {
                //console.log('aaa');
                document.querySelector('.vue-base64-file-upload-wrapper').querySelector('input[type=file]').click()
            },
            clearUploader() {
              this.question.img = null
            },
            getTags(tags) {
                this.question.tags = tags;
            },
            save() {
                let self = this;
                self.saving = true;
                let params = {
                    title: self.question.title,
                    content: self.question.content,
                    img: self.question.img,
                    color: self.question.color,
                    meinung: self.question.meinung,
                    themes: JSON.stringify(self.question.themes.map(function (key, val) { return key['name'] })),
                    survey: JSON.stringify(self.question.survey),
                    need_survey: self.show_survey,
                    communities: JSON.stringify(self.question.communities)
                };
                axios.post(`/posts/saver`,params, {headers: this.headers})
                    .then(function (response) {
                        //console.log(response)
                        window.location.href = response.data.url;
                    })
                    .finally(function () {
                    self.saving = false;
                });
            },
            close() {
                this.show = false
            },
            addVariant() {
                this.question.survey.push({txt:''});
            },
            removeVariant(index) {
                if ('id' in this.question.survey[index]) {
                    this.question.survey[index]['deleted'] = true
                } else {
                    this.question.survey.splice(index,1)
                }
            },
            restoreVariant(index) {
                this.question.survey[index]['deleted'] = false
            },
            onResize() {
                let el = document.querySelector('.question-window');
                if (el !== null && el.clientHeight > window.innerHeight) {
                    el.style.maxHeight = `${window.innerHeight-30}px`;
                    el.classList.add('with-scroll');
                } else {
                    // el.classList.remove('with-scroll');
                    // el.removeAttribute('style')
                }
            }
        },
        computed: {
            colorClass: function() {
                let result = this.question.color;
                if (this.question.img !== null) {
                    result = `${this.question.color} with-img`
                }
                return result
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
    @import 'question.scss';

    .with-scroll {
        overflow-y: scroll;
    }

    .themes {
        padding: 0 20px 20px 20px;
        margin-top: 0;

        .theme {
            margin: 0 10px 8px 0;
        }
    }

    small {
        color:red!important;
    }
    .c-green {
        color:green!important;
    }
    .deleted {
        text-decoration: line-through;
    }

</style>
