<template>
    <div class="communities form-group">
        <input type="hidden" name="user[raw_themes]" v-model="stringCommunities">
        <div class="communities form-group">
<!--            <input v-model="search_theme"  v-debounce:300ms="searchThemes" type="text" class="themes-search-input" placeholder="Suchen"/>-->
<!--            <div class="themes-suggestions">-->
<!--                <div class="theme-suggestion" v-for="(suggestion, index) in suggestions"  @click="addTheme(index)">-->
<!--                    <span class="theme-name">{{suggestion.name}}</span>-->
<!--                    <svg class="suggestion-control" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/><path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/></svg>-->
<!--                </div>-->
<!--            </div>-->

            <div class="themes" v-if="selected_themes.length > 0">
                <div v-for="(theme,index) in selected_themes" class="theme">
                    {{theme.name}}
                    <span class="closer" @click="removeTheme(index)">&times;</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const axios = require('axios');
    export default {
        components: {},
        name: 'communities',
        props:['csrf'],
        data: function () {
            return {
                value: null,
                options: [],
                suggestions: [],
                selected_themes: [],
                search_theme:'',
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
            }
        },
        mounted: function() {
            this.options = gon.themes;
            this.selected_themes = gon.current_themes;
        },
        methods: {
            searchThemes() {
                if (this.search_theme.length > 0) {
                    let self = this;
                    axios.get('/tags/lookup',{params:{q:self.search_theme,w:self.current_slugs,skip_new:'yes'}, headers: self.headers})
                        .then(function (response) {
                            self.suggestions = response.data.themes;
                            // console.log(response.data);
                        })
                } else {
                    this.suggestions = []
                }

            },
            addTheme(index) {
                //this.themes.push(this.suggestions[index]);
                this.selected_themes.push(this.suggestions[index]);
                this.suggestions = [];
                this.search_theme = '';
                //this.opened = false;
            },
            removeTheme(index) {
                this.selected_themes.splice(index,1);
            }
        },
        computed: {
            stringCommunities() {
                return JSON.stringify(this.selected_themes)
            }
        }

    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
    .themes-window {
        cursor: default;
        top:60px;
        left:0;
        padding: 20px 20px 0 20px;
        width: 260px;
    }

    .themes-title {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .themes-search-input {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #d8d8d8;
        outline: none;
        padding: 5px 13px 8px 13px;
        margin-bottom: 20px;
    }

    .themes-suggestions {
        width: 100%;
    }

    .theme-suggestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
        line-height: 40px;
        padding: 0 4px 0 8px;
        cursor: pointer;
        &:hover {
            color: #2c6e83;
        }

        @media (max-width: 991px) {
            line-height: 32px;
        }
    }

    .theme-name {
        white-space: nowrap; /* Отменяем перенос текста */
        overflow: hidden; /* Обрезаем содержимое */
        text-overflow: ellipsis;
        width: 355px;
    }

    .suggestion-control {
        height:24px;
        width:24px;
        min-width: 24px;
        margin-right: 6px;

        @media (max-width: 991px) {
            height:20px;
            width:20px;
            min-width: 20px;
        }
    }

    .themes {
        display: flex;
        flex-wrap: wrap;
        margin-top: 14px;
        .theme {
            display: flex;
            align-items: center;
            background: #f5f4f4;
            border-radius: 40px;
            padding: 0 20px;
            height: 40px;
            margin: 8px 8px 0 0;
            font-size: 14px;
            color: #333333;
            text-decoration: none;
            .closer {
                margin-left: 10px;
                cursor: pointer;
                font-size: 20px;
            }
        }
    }
</style>