(function() {

    document.addEventListener('click', function(event) {
        if (event.target.classList.contains('search-filter-switcher')) {
            event.preventDefault();
            el = document.getElementById('search-list');
            if (el.classList.contains('hidden')) {
                el.classList.remove('hidden');
                document.getElementById('search-filter-span').innerText = 'Schließen'
            } else {
                el.classList.add('hidden');
                document.getElementById('search-filter-span').innerText = 'Filter'
            }
        }
    }, false);

}).call(this);