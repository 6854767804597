<template>
    <div class="avatarer">
        <a class="avatarer__cropper-activator profile-stats__user-info__name" href="javascript:void(0)" @click="openCropper"> 
            <h1 class="mb-0 d-inline-block" style="font-size: inherit"> {{ nickname }} </h1>
            <svg class="pencil" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
        </a>

        <modal name="avatar-cropper" height="auto" width="320">
            <button class="cropper-closer base-icon-btn" @click="closeCropper">
                <span>&times;</span>
            </button>
            <cropper v-if="upload !== null"
                     classname="cropper"
                     :src="upload"
                     :stencilProps="{aspectRatio: 1}"
                     @change="change"
            ></cropper>
            <div v-else>
                <vue-base64-file-upload v-show="false"
                        placeholder=""
                        ref="uploader"
                        class="v1"
                        accept="image/png,image/jpeg"
                        image-class="w-100"
                        input-class="v1-input"
                        :disable-preview = false
                        @load="onImageUpload" />
            </div>

            <div style="padding:36px 30px 20px;">
                <div v-if="upload === null" class="pointer profile-button" @click="clickUploader">
                    <i class="fas fa-upload m-r-5"></i>
                    <span>Hochladen</span>
                </div>

                <div v-if="upload !== null" class="pointer profile-button m-b-10" @click="clearUploader">
                    <i class="fas fa-trash-alt m-r-5"></i>
                    <span>Löschen</span>
                </div>

                <div v-if="upload !== null" class="pointer profile-button" @click="saveAvatar">
                    <i class="fas fa-hdd m-r-5"></i>
                    <span>Speichern</span>
                </div>

            </div>

        </modal>
    </div>
</template>

<script>
    const axios = require('axios');
    import { Cropper } from 'vue-advanced-cropper';
    import VueBase64FileUpload from 'vue-base64-file-upload';
    export default {
        name: 'avatarer',
        components: {Cropper,VueBase64FileUpload},
        props: ['csrf','id','nickname'],
        data: function () {
            return {
                opened: false,
                mobile: false,
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                upload: null,
                saving: false,
                coords:null,
            }
        },
        mounted() {
            this.mobile = document.body.id === 'mobile-body';
        },
        methods: {
            openCropper() {
                this.$modal.show('avatar-cropper', {},{
                    width: this.mobile ? '100%' : '500px'
                });
            },
            closeCropper() {
                this.$modal.hide('avatar-cropper');
            },
            change({coordinates, canvas}) {
                this.coords = coordinates;
            },
            onImageUpload(dataUri) {
                this.upload = dataUri;
            },
            clickUploader() {
                document.querySelector('.vue-base64-file-upload-wrapper').querySelector('input[type=file]').click();
            },
            clearUploader() {
                this.upload = null
            },
            saveAvatar() {
                let self = this;
                self.saving = true;
                let params = {
                    img: self.upload,
                    coords: self.coords
                };
                axios
                    .post(`/users/save_avatar`,params, {headers: this.headers})
                    .then(function (response) {

                        let avatarImg = document.querySelector('.avatar-img');
                        if (avatarImg === null) {
                            let img = document.createElement('img');
                            img.src = response.data.img + '?c='+ Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                            img.classList.add('avatar-square');
                            img.classList.add('avatar-img');
                            document.querySelector('.profile-stats__cover').innerHTML = '';
                            document.querySelector('.profile-stats__cover').appendChild(img);
                        } else {
                            avatarImg.src = response.data.img + '?c='+ Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                        }

                        // let avatarImgs = document.querySelectorAll('.avatar-img');
                        // avatarImgs && Array.from(avatarImgs).forEach(function (avatarImg) {
                        //     avatarImg.src = response.data.img + '?c='+ Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                        // });

                        self.$modal.hide('avatar-cropper');
                    });
            }
        }
    }
</script>

<style scoped lang="scss">

    .avatarer {
        position: relative;
    }

    .avatarer__cropper-activator {
        display: block;
        text-decoration: none;

        .pencil {
            fill: rgba(32, 35, 51, .5);
            margin-left: 3px;
            margin-top: -3px;
        }

        &:hover .pencil {
            fill: #333333;
        }
    }

    .v--modal-overlay {
        z-index: 1100;
    }

    .cropper {
        height: 300px;
        background: #DDD;
    }

    .profile-button {
        color: #2c6e83;
        &:hover {
            color: white;
        }
    }

    .cropper-closer {
        position: absolute;
        right: 4px;
        top: 6px;
        font-size: 24px;
        z-index: 2;
    }

</style>