(function() {

    document.addEventListener('DOMContentLoaded', () => {

        let password = document.getElementById('newpass');
        let confirm_password = document.getElementById('newpass_confirmation')

        let validatePassword = function() {
            if (password.value != confirm_password.value) {
                confirm_password.setCustomValidity('Passwords Don\'t Match')
            } else {
                confirm_password.setCustomValidity('')
            }

        };

        if (password !== null) {
            password.onchange = validatePassword;
            confirm_password.onkeyup = validatePassword;
        }
    });



}).call(this);