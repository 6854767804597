<template>
    <div class="question-notification-bar best-answer-toggler best-answer-toggler--cancel" @click="makeItBest" v-if="isBest">
        <div class="question-notification-bar__content">
            Sie haben diese Antwort als die beste gewählt!
        </div>
        <button type="button" class="best-answer-toggler__cancel-btn"> Stornierung </button>
    </div>

    <div class="question-notification-bar best-answer-toggler" @click="makeItBest" v-else>
        <div class="question-notification-bar__content">
            Dies ist die beste Antwort auf meine Frage
        </div>
        <div class="notif-toggle__toggler">
            <input type="checkbox" />
            <span class="notif-toggle__slider"></span>
        </div>
    </div>
</template>

<script>
    const axios = require('axios');

    export default {
        name: 'bestToggle',
        props: ['csrf','id','best'],
        data: function () {
            return {
                confirm: false,
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                isBest: false
            }
        },
        watch: {
            best: {
                handler(current) {
                    this.isBest = current;
                },
                immediate: true
            }
        },
        methods: {
            makeItBest() {
                let self = this;
                axios.post(`/comments/${self.id}/best`,{}, {headers: this.headers});
                //self.confirm = false;
                this.isBest = !this.isBest;

            },
            toggleConfirm() {
                this.confirm = !this.confirm
            }
        }
    }
</script>

<style lang="scss" scoped>

    .best-answer-toggler {
        margin-left: 0;
        margin-right: 0;
    }

    .best-answer-toggler--cancel {
        background-color: rgba(32, 35, 51, .07);
        box-shadow: none;

        .question-notification-bar__content {
            color: rgba(32, 35, 51, .7)
        }

        .best-answer-toggler__cancel-btn {
            border: 0;
            background: transparent;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: #333333;
            cursor: pointer;
            transition: .1s ease-out;
            padding: 0;
            margin: 0;

            &:hover {
                opacity: .65;
            }
        }
    }

</style>
