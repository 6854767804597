(function() {
    runTopSearch = function() {
        el = document.getElementById('top-search');
        if (el.querySelector('input[type=text]').value.length > 0) {
            el.querySelector('form').submit();
        } else {
            window.location.href = '/';
        }

    };

    showTopSearch = function() {
        el = document.getElementById('top-search');
        el.classList.add('opened');
        document.getElementById('top-search-opener').classList.add('hidden');
        document.getElementById('top-links').classList.add('hidden');
    };

    closeTopSearch = function() {
        document.getElementById('top-search-opener').classList.remove('hidden');
        document.getElementById('top-links').classList.remove('hidden');
        el = document.getElementById('top-search');
        el.classList.remove('opened');
    };

    if (document.getElementById('top-search-opener')) {
        document.getElementById('top-search-opener').addEventListener('click', showTopSearch);
        document.getElementById('top-search-closer').addEventListener('click', closeTopSearch);
    }

    if (document.getElementById('top-search-runner')) {
        document.getElementById('top-search-runner').addEventListener('click', runTopSearch);
    }


    showMobileTopSearch = function() {
        el = document.getElementById('top-mobile-search');
        if (el.classList.contains('opened')) {
            el.classList.remove('opened');
        } else {
            el.classList.add('opened');
        }

        //document.getElementById('top-mobile-search-opener').classList.add('hidden');
        //document.getElementById('burger').classList.add('hidden');
        //document.getElementById('mobile-logo').classList.add('hidden');
    };

    closeMobileTopSearch = function() {
        document.getElementById('top-mobile-search-opener').classList.remove('hidden');
        document.getElementById('burger').classList.remove('hidden');
        document.getElementById('mobile-logo').classList.remove('hidden');
        el = document.getElementById('top-mobile-search');
        el.classList.remove('opened');
    };

    if (document.getElementById('top-mobile-search-opener')) {
        document.getElementById('top-mobile-search-opener').addEventListener('click', showMobileTopSearch);
        //document.getElementById('top-mobile-search-closer').addEventListener('click', closeMobileTopSearch);
    }

}).call(this);