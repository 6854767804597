(function() {

    document.addEventListener('click', function(event) {
        let target = event.target;
        let comment_id = null;
        if (target.classList.contains('c-updater')) {
            comment_id = target.dataset.id
        } else if (target.parentNode !== null && target.parentNode.classList.contains('c-updater')) {
            comment_id = target.parentNode.dataset.id
        }

        if (comment_id !== null) {
            let updater = document.getElementById(`comment-updater-${comment_id}`);
            let comment = document.getElementById(`comment-txt-${comment_id}`);
            if (updater.classList.contains('hidden')) {
                comment.classList.add('hidden');
                updater.classList.remove('hidden');
            } else {
                updater.classList.add('hidden');
                comment.classList.remove('hidden');
            }
        }

        if (target.classList.contains('edit-comment') || target.closest('.edit-comment') !== null) {
            if (target.classList.contains('edit-comment')) {
                var c_id = target.dataset.id
            } else {
                var c_id = target.closest('.edit-comment').dataset.id
            }
            document.getElementById(`comment-updater-${c_id}`).classList.remove('hidden');
            document.getElementById(`comment-txt-${c_id}`).classList.add('hidden');
        }


        if (target.classList.contains('comment-updater-closer') || target.closest('.comment-updater-closer') !== null) {
            if (target.classList.contains('comment-updater-closer')) {
                var c_id = target.dataset.id
            } else {
                var c_id = target.closest('.comment-updater-closer').dataset.id
            }
            document.getElementById(`comment-updater-${c_id}`).classList.add('hidden');
            document.getElementById(`comment-txt-${c_id}`).classList.remove('hidden');

        }

        if ((target.parentNode !== null && target.parentNode.classList.contains('comment-closer')) || target.classList.contains('comment-closer')) {
            let form = document.getElementById('new-comment');
            form.classList.add('hidden');
            form.parentNode.querySelector('.reply-link').classList.add('active');
        }

        if (target.parentElement.classList.contains('reply-link')) {
            event.preventDefault();
            let nick = target.parentElement.dataset.nick;
            let form = document.getElementById('new-comment');

            current_link = form.closest('.comment-reply').childNodes[0];

            link = target.parentElement;
            parent = link.parentElement;

            if (current_link.parentElement == parent || (current_link.nextElementSibling == form && !form.classList.contains('hidden') )) {
                current_link.classList.toggle("active");
            }
            
            if (current_link !== link) {
                link.classList.toggle("active");
            }

            parent.insertAdjacentElement('beforeEnd', form);

            if (form.classList.contains('hidden')) {
                form.classList.remove('hidden');
            }

            document.getElementById('root-link').classList.add('active');

            if (link.dataset.parentId === 'no') {
                document.getElementById('comment_parent_id').value = '';
            } else {
                document.getElementById('comment_parent_id').value = link.dataset.parentId;
            }

            window.comment_editor.editing.view.focus();
            if (nick !== undefined) {
                window.comment_editor.setData(`<p>@${nick}&nbsp;</p>`);
                window.comment_editor.model.change( writer => {
                    writer.setSelection( writer.createPositionAt( window.comment_editor.model.document.getRoot(), 'end' ) );
                } );
            } else {
                window.comment_editor.setData('');
            }
        }

        if (target.classList.contains('expander-link')) {
            let id = target.dataset.parent;
            let parent_wrapper = document.getElementById(`children_${id}`);

            Array.from(parent_wrapper.getElementsByClassName('comment')).forEach(function(element) {
                element.classList.remove('hidden');
            });
            target.closest('.comments-expander').classList.add('hidden');
        }

        if (target.classList.contains('show-comment')) {
            parent = target.parentNode;
            id = parent.dataset.n;
            parent.innerHTML = gon.comments[id];
            btn = document.querySelector('[data-showbutton="' + id + '"]');
            if (btn !== null) {
                btn.classList.remove('hidden');
            }
            controls = document.querySelector('[data-controls="' + id + '"]')
            if (controls !== null) {
                controls.classList.remove('hidden');
            }
        }

        if (target.closest('.communication-rules-activator')) {
            let rulesActivatorEl = target.closest('.communication-rules-activator');
            let rulesEl = target.closest('#new-comment').querySelector('.communication-rules-wrapper');

            rulesActivatorEl.style.display = 'none';
            rulesEl.style.display = 'block';
        }

        if (target.closest('.com-rules__accordion')) {
            let accordionEl = target.closest('.com-rules__accordion');

            if (accordionEl.classList.contains('com-rules__accordion--active')) {
                accordionEl.classList.remove('com-rules__accordion--active');
                accordionEl.nextElementSibling.style.display = 'none';
            }
            else {
                accordionEl.classList.add('com-rules__accordion--active');
                accordionEl.nextElementSibling.style.display = 'block';
            }
        }

        if (target.closest('.com-rules__close-btn')) {
            let rulesEl = target.closest('.communication-rules-wrapper');
            let rulesActivatorEl = target.closest('#new-comment').querySelector('.communication-rules-activator');

            rulesActivatorEl.style.display = 'flex';
            rulesEl.style.display = 'none';
        }

        //чекаем на мобильном клики по текст инпутам чтобы прятать панельку при открытой клавиатуре (и по ckeditor тоже)
        if (document.body.id === 'mobile-body' && ((target instanceof HTMLInputElement && target.type === 'text') || target.closest('.ck-editor') !== null ))  {
            hideMobileControl()
        }
    }, false);

}).call(this);