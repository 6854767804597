(function() {

    function showLoginOnce() {
        if (localStorage.getItem('loginOnce') !== 'yes') {
            localStorage.setItem('loginOnce','yes')
            showLoginInformer('');
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        if (document.getElementById('login-informer')) {
            setTimeout(showLoginOnce, 10000);
        }
    })

}).call(this);
