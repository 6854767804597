(function() {

    function closeMenus() {
        let dropdowns = document.getElementsByClassName("dropdown-content");
        Array.from(dropdowns).forEach(function(element) {
            element.classList.remove('show');
        })
    }

    window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
            let parent = event.target.closest('.dropdown');
            if (parent !== null) {
                let mnu = parent.querySelector('.dropdown-content');
                if (!mnu.classList.contains('show')) {
                    closeMenus()
                }
            } else {
                closeMenus()
            }
        }
    };

    drops = document.getElementsByClassName('dropbtn');
    Array.from(drops).forEach(function(element) {
        element.addEventListener("click", function(){
            let parent = element.closest('.dropdown');
            let mnu = parent.querySelector('.dropdown-content');
            if (mnu !== null) {
                mnu.classList.toggle("show");
            }
        });
    });
}).call(this);