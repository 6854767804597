(function() {

    // function hidePostReport() {
    //     document.getElementById('report-controls').classList.add('hidden')
    // }
    //
    // function showPostReport() {
    //     document.getElementById('report-controls').classList.remove('hidden')
    // }

    function setPostReport() {
        document.getElementById('report-model').value = this.dataset.model;
        document.getElementById('report-model-id').value = this.dataset.id;
        if (this.dataset.model === 'User') {
            document.getElementById('report-controls').classList.add('hidden')
        } else {

        }
    }

    elements = document.getElementsByClassName('post-report');

    Array.from(elements).forEach(function(element) {
        element.addEventListener('click', setPostReport);
    });


}).call(this);