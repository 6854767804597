(function() {

    document.querySelectorAll('[data-autoresize]').forEach(function (element) {
        element.style.boxSizing = 'border-box';
        element.style.height = 'auto';
        let offset = element.offsetHeight - element.clientHeight;
        element.style.height = element.scrollHeight + offset + 'px';
        document.addEventListener('input', function (event) {
            event.target.style.height = 'auto';
            event.target.style.height = event.target.scrollHeight + offset + 'px';
        });
        element.removeAttribute('data-autoresize');
    });

}).call(this);