<template>
    <div class="question-window">

        <div class="question-content" v-bind:class="colorClass" v-bind:style="question.img !== null ? `background-image:url(${question.img})` : '' ">
            <textarea-autosize
                    autofocus
                    maxlength="150"
                    class="question-body"
                    :placeholder="titlePlaceholder"
                    ref="qTitle"
                    v-model="question.title"
            />
            <span style="color:#2c6e83;" v-if="question.title.length > 100">Dir bleiben noch <b>{{150 - question.title.length}}</b> Zeichen.</span>
        </div>
        <div class="question-content question-content--small" v-if="show_content">
            <textarea-autosize
                    class="question-body q-content"
                    maxlength="3000"
                    rows="2"
                    placeholder="Optional: Beschreibe noch etwas genauer"
                    ref="qContent"
                    v-model="question.content"
            />
            <span style="color:#2c6e83;" v-if="question.content !== null && question.content.length > 2500">Dir bleiben noch <b>{{3000 - question.content.length}}</b> Zeichen.</span>
        </div>
        <div class="themes" v-if="question.themes.length > 0">
            <div v-for="(theme,index) in question.themes" :key="index" class="theme">
                {{theme.name}}
                <span class="closer" @click="removeTheme(index)">&times;</span>
            </div>
        </div>
        <div class="question-controls">

            <div class="color-buttons">
                <div v-for="(color, index) in colors" :key="index" v-bind:class="`control-button ${color}`" @click="colorQuestion(color)">
                    <img v-if="color === question.color" src="/img/check.png" alt="Check icon">
                    <img v-else src="/img/lines.png" alt="Line icon">
                </div>
            </div>

            <div class="more-buttons">
                <themes :csrf="csrf" :current_themes="question.themes" @get-theme="getTheme"/>
                <div class="grey-button" @click="question.img === null ? clickUploader() : clearUploader()">
                    <img src="/img/question-img.png" alt="Löschen">
                    <span v-if="question.img !== null">Titelbild Löschen</span>
                    <span v-else>Titelbild</span>
                </div>
                <div class="grey-button" @click="toggleContent" v-bind:class="show_content ? 'dark' : ''">
                    <img src="/img/question-details.png" alt="Details">
                    Details
                </div>
                <div class="grey-button" @click="toggleSurvey" v-bind:class="show_survey ? 'dark' : ''">
                    <img src="/img/votes.png" alt="Umfragen">
                    Umfragen
                </div>

            </div>

            <div class="question-survey" v-if="show_survey">
                <div v-for="(variant, index) in question.survey" class="form-group" :key="index">
                    <label :for="'variant_'+index">
                        <span v-bind:class="variant['deleted'] ? 'deleted' : ''">Antwort {{index+1}}</span>

                        <small v-if="variant['deleted']" class="false-link c-green" v-on:click="restoreVariant(index)">Restore</small>
                        <small v-else-if="(question.survey.length > 2) && (!('deleted' in variant))" class="false-link" v-on:click="removeVariant(index)">Löschen</small>

                    </label>
                    <input  type="text" v-model="variant.txt" class="form-control" :id="'variant_'+index" maxlength="70" required>
                </div>
                <div v-if="question.survey.length > 0">
                <span v-if="question.survey.length < 7" v-on:click="addVariant()" class="false-link d-flex align-items-center">
                    <svg class="suggestion-control" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/><path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/></svg>
                    Weitere Antwort
                </span>
                </div>
            </div>

            <div v-show="false" class="img-uploader">
                <vue-base64-file-upload
                        placeholder=""
                        ref="uploader"
                        class="v1"
                        accept="image/png,image/jpeg"
                        image-class="w-100"
                        input-class="v1-input"
                        :disable-preview = false
                        @load="onImageUpload" />
            </div>
        </div>
        <div class="question-tags m-b-150">
            <p>Bitte beachte unsere <a href="/guide" target="_blank">Richtlinien</a>, bevor Du Deinen Beitrag abschickst</p>

            <div v-if="extended === 'yes'">
                <div class="form-group pb-3">
                    <label class="form-check form-checkbox-wrapper">
                        Für Werbung
                        <input class="form-check-input" id="defaultCheck1" type="checkbox" v-model="question.special">
                        <div class="form-check__checkbox">
                            <i class="fas fa-check"></i>
                        </div>
                    </label>
                </div>

                <div class="form-group pb-3">
                  <label class="form-check form-checkbox-wrapper">
                    Nachrichten
                    <input class="form-check-input" id="defaultCheck2" type="checkbox" v-model="question.news">
                    <div class="form-check__checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                  </label>
                </div>


                <div class="form-group">
                    <label>Fragestunde</label>
                    <datetime format="DD-MM-YYYY H:i:s" width="300px" v-model="question.new_time"></datetime>
                </div>
            </div>


            <div class="button-wrapper">
                <span id="question-form-submit" v-if="question.title.length > 3" @click="save" class="btn btn-primary">
                    {{saving ? '...' : 'Posten'}}
                </span>
                    <span v-else class="btn btn-primary disabled">
                    Posten
                </span>
            </div>
        </div>
    </div>


</template>

<script>
    // import VueTagsInput from "@johmun/vue-tags-input";
    import datetime from 'vuejs-datetimepicker';
    const axios = require('axios');
    import communities from './communities'
    import themes from './themes'
    import tags from './tags'
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'question',
        components: {themes,communities,VueBase64FileUpload,tags,Multiselect,datetime},
        props:['id','csrf','user','extended'],
        data: function () {
            return {
                show: true,
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                colors: ['orange','green','teal','dirt','purple','cloud','red'],
                show_content: false,
                show_survey: false,
                saving: false,
                options: [],
                tag:'',
                question: {
                    communities:[],
                    survey:[],
                    title:'',
                    color: null,
                    content:null,
                    img: null,
                    themes:[],
                    meinung: true,
                    special: false,
                    new_time: '',
                    news: false
                }
            }
        },
        mounted: function() {
            if (this.id !== undefined) {
                let self = this;
                axios.get(`/posts/get?id=${self.id}`, {headers: this.headers})
                    .then(function (response) {
                        self.question = response.data.post;
                        self.show_content = response.data.show_content;
                        self.show_survey = response.data.show_survey;
                    });
            } else {
                if (gon.start_tag !== undefined) {
                    this.question.themes.push(gon.start_tag)
                }
            }

            let falseBtn = document.getElementById('false-question-submit');
            if (falseBtn !== null) {
                falseBtn.classList.remove('hidden');
                falseBtn.addEventListener('click', e => {
                    if (!e.toElement.classList.contains('disabled')) {
                        document.getElementById('question-form-submit').click()
                    }
                });
            }
        },
        methods: {
            toggleSurvey() {
                if (this.question.survey.length === 0) {
                    this.question.survey = [{txt:''},{txt:''}]
                }
                this.show_survey = !this.show_survey
            },
            setMeinung(result) {
              this.question.meinung = result;
              if (result) {
                  document.getElementById('meinung-sand-box').classList.remove('hidden');
                  document.getElementById('frage-sand-box').classList.add('hidden');
              } else {
                  document.getElementById('meinung-sand-box').classList.add('hidden');
                  document.getElementById('frage-sand-box').classList.remove('hidden');
              }
            },
            getTheme(theme) {
                this.question.themes.push(theme)

            },
            removeTheme(index) {
                this.question.themes.splice(index,1);
            },
            colorQuestion(color) {
                if (this.question.color === color) {
                    if (this.question.img === null) {
                        this.question.color = null;
                    }
                } else {
                    this.question.color = color;
                }
            },
            toggleContent() {
              this.show_content = !this.show_content
            },
            onImageUpload(dataUri) {
                this.question.img = dataUri;
                this.question.color = this.colors[Math.floor(Math.random() * this.colors.length)];
            },
            clickUploader() {
                document.querySelector('.vue-base64-file-upload-wrapper').querySelector('input[type=file]').click()
            },
            clearUploader() {
              this.question.img = null
            },
            getTags(tags) {
                this.question.tags = tags;
            },
            save() {
                let self = this;
                self.saving = true;
                let params = {
                    id: self.id === undefined ? null : self.id,
                    user: self.user === undefined ? null : self.user,
                    title: self.question.title,
                    content: self.question.content,
                    img: self.question.img,
                    color: self.question.color,
                    meinung: self.question.meinung,
                    themes: JSON.stringify(self.question.themes.map(function (key, val) { return key['name'] })),
                    survey: JSON.stringify(self.question.survey),
                    need_survey: self.show_survey,
                    communities: JSON.stringify(self.question.communities),
                    special: self.question.special,
                    news: self.question.news,
                    new_time: self.question.new_time
                };
                axios.post(`/posts/saver`,params, {headers: this.headers})
                    .then(function (response) {
                        window.location.href = response.data.url;
                    })
                    .finally(function () {
                    //self.saving = false;
                    });
            },
            close() {
                this.show = false
            },
            addVariant() {
                this.question.survey.push({txt:''});
            },
            removeVariant(index) {
                if ('id' in this.question.survey[index]) {
                    this.question.survey[index]['deleted'] = true
                } else {
                    this.question.survey.splice(index,1)
                }
            },
            restoreVariant(index) {
                this.question.survey[index]['deleted'] = false
            },
            onResize() {
                let el = document.querySelector('.question-window');
                if (el !== null && el.clientHeight > window.innerHeight) {
                    el.style.maxHeight = `${window.innerHeight-30}px`;
                    el.classList.add('with-scroll');
                } else {
                    // el.classList.remove('with-scroll');
                    // el.removeAttribute('style')
                }
            }
        },
        computed: {
            colorClass: function() {
                let result = this.question.color;
                if (this.question.img !== null) {
                    result = `${this.question.color} with-img`
                }
                return result
            },
            titlePlaceholder: function() {
                if (this.user !== undefined) {
                    const capitalizedUsername = this.user.charAt(0).toUpperCase() + this.user.slice(1);

                    return `Deine Frage wird von ${ capitalizedUsername } und anderen Nutzern auf meinung.de beantwortet.`
                }
                
                return 'Schreibe Titel Deines Beitrages oder Formuliere deine Frage';
            }
        },
        watch: {
            question: {
                handler: function (newQ, oldQ) {
                    const falseQSubmit = document.getElementById('false-question-submit');

                    if (newQ.title.length > 3) {
                        falseQSubmit && falseQSubmit.classList.remove('disabled')
                    } else {
                        falseQSubmit && falseQSubmit.classList.add('disabled')
                    }
                },
                deep:true
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
    @import 'question.scss';

    .current {
        background-color: #2e5b73;
        border-color: #2e5b73;
    }

    .with-scroll {
        overflow-y: scroll;
    }

    .btns {
        .btn {
            padding: 7px 40px;
            margin-left: 10px;
        }
    }

    .themes {
        padding: 0 20px 20px 20px;
        display: flex;
        flex-wrap: wrap;
        .theme {
            display: flex;
            background: #f5f4f4;
            border-radius: 40px;
            padding: 10px 20px;
            margin: 0 10px 8px 0;
            font-size: 14px;
            color: #333333;
            text-decoration: none;
            .closer {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    span.btn {
        cursor:pointer;
    }

    .c-green {
        color:green!important;
    }
    .deleted {
        text-decoration: line-through;
    }

    .disabled {
        background-color: #f5f4f4;
        border-color: #f5f4f4;
        color: #a6c1c9;
    }

    .button-wrapper {
        display: flex;
        flex-direction: row-reverse;
    }

    .question-content {
        padding: 36px 20px 0;
        margin-bottom: 0;
        border-color: #e9ecef;
        border-style: solid;

        &.question-content--small {
            border-width: 0 1px;
            border-radius: 0;
        }
    }

    .question-content:not(.question-content--small) {
        border-width: 1px 1px 0;
    }

    @media (max-width: 991px) {
        .themes {
            padding: 0 0 8px;
            margin-top: 0;

            .theme {
                font-size: 12px;
                padding: 0 12px;
                height: 32px;
            }
        }
        .question-content, .question-controls, .question-tags {
            padding: 5px;
            border: 0;
        }
        .question-content {
            padding: 12px 10px 0;
            margin: 0 -5px 12px;
            border: 0;
            border-radius: 6px;

            &.question-content--small {
                padding-top: 0;
                margin-bottom: 0;
            }
        }
        .question-body {
            line-height: 28px;
            font-size: 20px;
        }
        .color-buttons {
            margin-bottom: 8px;
        }
        .more-buttons {
            margin-bottom: 0;
        }
        .control-button {
            width: 34px;
            height: 34px;
            margin-right: 8px;
        }
        .question-tags { 
            margin-bottom: 0 !important;

            .button-wrapper {
                display: none;
            }
        }
        .question-survey {
            margin-top: 8px;

            .form-group {
                margin-bottom: 1rem;
                font-size: 14px;

                label {
                    margin-bottom: .25rem;
                }

                .form-control {
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                }
            }
        }
    }

</style>