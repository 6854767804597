<template>
    <div class="root" @mouseover="showWindow" @mouseleave="hideWindow">
        <div class="counter" v-show="unreadCount > 0">
            {{unreadCount}}
        </div>
        <a href="/events" title="Mitteilungen" class="mnu">
            <svg @click.self="toggleOpened" height="20px" x="0px" y="0px"
                 viewBox="0 0 90 90" enable-background="new 0 0 90 90" xml:space="preserve">
                  <path d="M74.385,67.13c-3.353-3.563-3.493-6.869-3.401-15.612c0.029-2.811,0.063-5.997-0.065-9.722
                    c-0.002-0.035-0.003-0.07-0.005-0.104c-0.817-13.071-7.34-20.282-14.235-23.89v-6.286C56.678,5.182,51.497,0,45.163,0h-0.234
                    c-6.411,0-11.608,5.197-11.608,11.608v6.147c-6.896,3.588-13.415,10.799-14.236,23.937c-0.002,0.035-0.003,0.07-0.005,0.104
                    c-0.128,3.726-0.095,6.912-0.065,9.723c0.092,8.741-0.048,12.048-3.399,15.609c-1.259,1.338-1.978,3.164-1.616,4.965
                    c0.528,2.629,2.785,4.417,5.347,4.417h11.822c0.023,0.329,0.04,0.658,0.087,0.988c0.874,6.225,6.037,11.211,12.277,11.856
                    c0.48,0.05,0.961,0.074,1.439,0.074c3.431,0,6.764-1.263,9.319-3.567c2.677-2.414,4.288-5.781,4.538-9.352h11.823
                    c2.561,0,4.817-1.788,5.347-4.416C76.363,70.293,75.644,68.468,74.385,67.13z M40.821,11.608c0-2.266,1.843-4.108,4.108-4.108h0.158
                    c2.256,0,4.091,1.843,4.091,4.108v3.557c-1.262-0.246-2.463-0.385-3.563-0.435c-0.3-0.014-0.908-0.013-1.2-0.001
                    c-1.109,0.043-2.321,0.177-3.594,0.419V11.608z M49.269,80.292c-1.358,1.225-3.123,1.793-4.965,1.603
                    c-2.723-0.282-4.995-2.375-5.549-5.046c-0.036-0.174,0.105-0.339,0.283-0.339h11.917c0.178,0,0.319,0.165,0.283,0.339
                    C50.964,78.164,50.284,79.377,49.269,80.292z M62.6,69.01H27.399c-1.667,0-2.843-1.65-2.278-3.218
                    c1.501-4.166,1.452-8.764,1.394-14.351c-0.028-2.718-0.061-5.797,0.06-9.332c0.71-11.092,6.351-15.998,11.185-18.168
                    c0.504-0.094,0.973-0.284,1.384-0.557c2.716-0.981,4.961-1.136,5.566-1.159c0.14-0.005,0.409-0.007,0.563-0.001
                    c0.993,0.045,3.38,0.269,6.111,1.321c0.075,0.034,0.151,0.062,0.229,0.091c5.012,2.005,11.07,6.832,11.814,18.473
                    c0.12,3.534,0.088,6.613,0.06,9.331c-0.059,5.588-0.106,10.186,1.394,14.352C65.443,67.36,64.268,69.01,62.6,69.01z"/>
                  </svg>
            {{mobile ? '' : 'Mitteilungen'}}
        </a>

        <div class="popup base-dropdown" v-show="opened">
            <div id="event-settings" class="base-icon-btn" @click="toggleSettings">
                <i class="fas fa-cog"></i>
            </div>
            <div class="settings" v-if="show_settings">
                <h5>Benachrichtigungen</h5>

                <div class="form-group" v-for="(setting,index) in settings" :key="index">
                    <div class="form-check">
                        <input class="form-check-input" v-bind:id="`check${index}`" type="checkbox" v-model="setting.result">
                        <label class="form-check-label" v-bind:for="`check${index}`" style="font-size:14px;">{{setting.human_name}}</label>
                    </div>
                </div>

                <a @click="saveSettings" href="javascript:void(0)" class="btn btn-primary">Speichern</a>
            </div>
            <div v-else>
                <div class="read-all" v-if="unreadCount > 0">
                    <a href="javascript:void(0)" @click="readAll">Alle als gelesen markieren</a>
                </div>
                <div class="event" v-for="(event,index) in events" :key="index" v-if="index < 9" v-bind:class="event.read ? '' : 'unread'">
                <span class="icon" v-bind:class="event.read ? '' : 'colored'">
                    <i v-bind:class="event.icon_class"></i>
                </span>
                    <div class="desc" v-html="event.txt"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const axios = require('axios');
    export default {
        name: 'notificator',
        props: ['csrf'],
        data: function () {
            return {
                mobile: false,
                opened: false,
                show_settings: false,
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                events: [],
                settings:[]
            }
        },
        channels: {
            NotificationChannel: {
                connected() {},
                rejected() {},
                received(data) {this.events.unshift(JSON.parse(data));},
                disconnected() {}
            }
        },
        mounted() {
            this.mobile = (document.body.id === 'mobile-body')
            this.$cable.subscribe({
                channel: 'NotificationChannel'
            });
            let self = this;
            axios.get(`/events.json`, {headers: this.headers})
                .then(function (response) {
                    self.events = response.data.events;
                    self.settings = response.data.settings;
                    //console.log(response.data);
                });

        },
        methods: {
            toggleSettings() {
                this.show_settings = !this.show_settings
            },
            toggleOpened() {
                if (this.events.length === 0) {
                    window.location.href = '/events'
                } else {
                    this.opened = !this.opened
                }
            },
            showWindow() {
                if (this.events.length > 0) {
                    this.opened = true
                }
            },
            hideWindow() {
                this.opened = false
            },
            readAll() {
                let self = this;
                for (let event of self.events) {
                    event.read = true
                }
                axios.post(`/events/read_all`,{}, {headers: this.headers})
                    .then(function (response) {

                    });
            },
            saveSettings() {
                let self = this;
                let params = this.settings.map(function(setting) {
                    return {[setting.name]: setting.result};
                });
                axios.post(`/events/save_settings`,{settings:JSON.stringify(params)}, {headers: this.headers})
                    .then(function (response) {
                    });

                self.show_settings = false;
            }
        },
        computed: {
            unreadCount() {
                return this.events.filter(el => !el.read).length
            }
        }
    }
</script>

<style lang="scss" scoped>
    .root {
        position: relative;
        height: 40px;
        display: flex;
        align-items: center;
    }

    #event-settings {
        position: absolute;
        right: 8px;
        top: 10px;
        color: #333333;
    }

    .settings {
        padding: 20px;
    }

    .event {
        border: none;
        margin-bottom: 0;
        padding: 10px 20px;
    }

    .popup {
        // left:-175px;
        right: 50%;
        transform: translateX(25%);
        top:36px;
        width: 450px;
        padding: 25px 0;
    }

    .counter {
        position: absolute;
        background: #c20000;
        color: white;
        font-size: 10px;
        top: -3px;
        right:3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        width:16px;
        height:16px;
        font-weight: 600;
    }

    .icon {
        font-size: 18px;
        padding: 0;
        width: auto;
        margin-right: 10px;
    }

    .unread {
        background-color: #f5f4f4;
    }

    .colored {
        i {
            color: #2c6e83;
        }
    }

    .desc {
        width: 100%;
        font-size: 14px;
        display: block;
    }

    .read-all {
        text-align: center;
        margin-bottom: 10px;
    }

    @media (max-width: 500px) {
        .counter {
            top: -3px;
            right: -6px;
        }

        .popup {
            width: 300px;
        }

    }

</style>