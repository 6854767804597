(function() {

    function setModalLogin() {
        document.getElementById('modal-2-title').innerHTML = this.dataset.title;
    }

    elements = document.getElementsByClassName('modal-login');

    Array.from(elements).forEach(function(element) {
        element.addEventListener('click', setModalLogin);
    });


}).call(this);