(function() {

    let vote_clicker = function() {
        survey = this.closest('.survey');
        survey.querySelector('.vote_sender').classList.remove('hidden');
    };

    variants = document.getElementsByClassName('radio-variant');

    Array.from(variants).forEach(function(element) {
        element.addEventListener('change', vote_clicker);
    });

}).call(this);