(function() {

  function buildTagsMenu(source_tags) {
    let list = document.getElementById('tag-menu-list');
    list.innerHTML = '';
    document.getElementById('tag-menu-counter').innerText = source_tags.length - 1;
    Array.from(source_tags).forEach(function(element) {
      if (!element.classList.contains('tag-opener')) {
        let parent = document.createElement('div');
        let name = document.createElement('div');
        let link = document.createElement('a');
        parent.classList.add('tag-line');
        name.classList.add('name');
        name.innerText = element.text
        link.href = element.href
        link.text = 'Zu Thema gehen'
        parent.appendChild(name);
        parent.appendChild(link);
        list.appendChild(parent);
      }
    })
    showTagsInformer();
  }

  document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.getElementsByClassName('tag-opener')).forEach(function(element) {
      element.addEventListener("click", function(){
        let tags = element.closest('.communities').querySelectorAll('.community');
        if (document.body.id === 'full') {
          Array.from(tags).forEach(function(tag) {
            tag.classList.remove('hidden');
          })
          element.classList.add('hidden');
        } else {
          buildTagsMenu(tags)
        }
      });
    });
  });





}).call(this);