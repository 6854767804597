/* eslint no-console: 0 */
import ActionCable from "actioncable";

require('rails-ujs').start();
require('../custom/vote');
require('../custom/enter_prevent');
// require('../custom/dropdown');
require('../custom/dropdown_new');
require('../custom/top_search');
require('../custom/comments');
require('../custom/tags');
require('../custom/search_filter');
require('../custom/text_area_autosize');
require('../custom/password_confirmation');
require('../custom/unsaved_alarm');
require('../custom/reports');
require('../custom/modal_login');
require('../custom/stuff');
require('../custom/comment_timer');
require('../custom/informers');
require('../custom/ten_seconds_login');
require('../custom/avatar_reminder');


import MicroModal from 'micromodal';
import Swiper from 'swiper';
import Vue from 'vue/dist/vue.common.js';
import communities from '../mein/communities';
import question from '../mein/question/question'
import question2 from '../mein/question2/question'
import qmenu from '../mein/qmenu/qmenu'
import cmenu from '../mein/cmenu/cmenu'
import cvotes from '../mein/cvotes/cvotes'
import themes from '../mein/themes/themes'
import TagSearch from '../mein/tag_search/tag_search'
// import best from '../mein/best/best'
import bestToggle from '../mein/best/bestToggle'
import notificator from '../mein/notificator/notificator'
import Avatarer from '../mein/avatarer/avatarer'
import mcontrol from '../mein/mcontrol/mcontrol'
import TextareaAutosize from 'vue-textarea-autosize'
import vueDebounce from 'vue-debounce'
import ActionCableVue from 'actioncable-vue';
import VModal from 'vue-js-modal'
import 'lazysizes';



/* Progress bar */
let progressBarPercent = 0;
let progressBarTimeoutId = null;

const progressBarWrapperEl = document.createElement('div');
const progressBarEl = document.createElement('div');

progressBarWrapperEl.classList.add('progress-linear');
progressBarEl.classList.add('progress-linear-bar');
progressBarEl.id = 'progressbar';
progressBarEl.style.width = progressBarPercent + '%';

progressBarWrapperEl.appendChild(progressBarEl);

document.body.insertAdjacentElement('afterbegin', progressBarWrapperEl);

function increaseProgressBarPercent() {
    progressBarPercent += 0.6;
    progressBarEl.style.width = progressBarPercent + '%';

    progressBarTimeoutId = setTimeout(increaseProgressBarPercent, 40);
}

function removeProgressBar() {
    clearTimeout(progressBarTimeoutId);
    progressBarWrapperEl.style.opacity = 0;

    progressBarWrapperEl.addEventListener('transitionend', function () {
        this.remove();
    }, { once: true });    
}

increaseProgressBarPercent();

window.onload = function () {
    if (progressBarEl.style.width == '100%') {
        removeProgressBar();
    }
    else {
        setTimeout(function () {
            progressBarEl.style.width = '100%';

            removeProgressBar();
        }, 200);
    }
};

window.meinung = {};

Vue.use(TextareaAutosize);
Vue.use(vueDebounce);
Vue.use(VModal);

Vue.directive('click-outside',
  {
      bind: function(el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
              // here I check that click was outside the el and his childrens
              if (!(el == event.target || el.contains(event.target))) {
                  // and if it did, call method provided in attribute value
                  vnode.context[binding.expression](event);
              }
          };
          document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function(el) {
          document.body.removeEventListener('click', el.clickOutsideEvent)
      },
  });


let ws_path = 'wss://meinung.de/cable';
if (document.getElementById('development') ) {
    ws_path = 'ws://localhost:3000/cable'
}

Vue.use(ActionCableVue, {
    debug: true,
    debugLevel: 'error',
    connectionUrl: ws_path,
    connectImmediately: true,
});

MicroModal.init();

window.run_question_form = function (id = null) {
    if (window.question !== undefined) {
        window.question.$destroy();
        if (document.querySelector('#question-screen')) {
            document.querySelector('#question-screen').remove()
        }
    }
    let vue_div = document.createElement('div');
    vue_div.id = 'question-frm';
    if (id !== null) {
        vue_div.dataset['id'] = id
    }
    document.body.appendChild(vue_div);
    window.question = new Vue({
        render: h => h(question,{
            props:{
                id:id,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        }),
    }).$mount(vue_div)
}; 

document.addEventListener('DOMContentLoaded', () => {

    document.addEventListener('lazybeforeunveil', function(e){
        var bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });


    let current_path = window.location.pathname;

    let vts = document.getElementById('vue-tags-search');
    if (vts) {
        new Vue({
            render: h => h(TagSearch,{
                props:{
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                }
            }),
        }).$mount(vts)
    }

    let qfrm = document.getElementById('q-frm');
    if (qfrm) {
        new Vue({
            render: h => h(question2,{
                props:{
                    id:qfrm.dataset.id,
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    user:qfrm.dataset.user,
                    extended:qfrm.dataset.extended
                }
            }),
        }).$mount(qfrm)
    }

    let mc = document.getElementById('mcontrol');
    if (mc) {
        new Vue({
            render: h => h(mcontrol,{
                props:{
                    usr:mc.dataset.usr,
                }
            }),
        }).$mount(mc)
    }

    let el = document.getElementById('avatarer');
    if (el) {
        new Vue({
            render: h => h(Avatarer,{
                props:{
                    id:el.dataset.id,
                    nickname:el.dataset.nickname,
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }),
        }).$mount(el)
    }


    let elements = document.getElementsByClassName('q-mnu');
    Array.from(elements).forEach(function(element) {
        new Vue({
            render: h => h(qmenu,{
                props:{
                    id:element.dataset.id,
                    edit:element.dataset.edit,
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }),
        }).$mount(element)
    });

    let els = document.getElementsByClassName('c-mnu');
    Array.from(els).forEach(function(element) {
        new Vue({
            render: h => h(cmenu,{
                props:{
                    id:element.dataset.id,
                    slug:element.dataset.slug,
                    showpost:element.dataset.showpost,
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }),
        }).$mount(element)
    });

    let votes = document.getElementsByClassName('cvote');
    Array.from(votes).forEach(function(element) {
        new Vue({
            render: h => h(cvotes,{
                props:{
                    id:element.dataset.id,
                    slug:element.dataset.slug,
                    likes:element.dataset.likes,
                    dislikes:element.dataset.dislikes,
                    liked:element.dataset.liked !== undefined,
                    disliked: element.dataset.disliked !== undefined,
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }),
        }).$mount(element)
    });

    let notificators = document.getElementsByClassName('notificator');
    Array.from(notificators).forEach(function(element) {
        new Vue({
            render: h => h(notificator,{
                props:{
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                }
            }),
        }).$mount(element)
    });

    let qNotificationTogglers = document.querySelectorAll('[data-notifications-toggle-post-id]');
    Array.from(qNotificationTogglers).forEach(function (element) {
        element.addEventListener('click', function () {
            let slug = element.dataset.slug;
            let checkboxEl = this.querySelector('input[type="checkbox"]');
            checkboxEl.checked = !checkboxEl.checked;
            if (checkboxEl.checked) {
                fetch(`/posts/${slug}/subscribe`, {
                    method: 'POST'
                })
            }
        });
    });

    Array.from(document.getElementsByClassName('themes-selector')).forEach(function(element) {
        new Vue({
            render: h => h(themes,{
                props:{
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                }
            }),
        }).$mount(element)
    });

    Array.from(document.getElementsByClassName('settings-themes')).forEach(function(element) {
        new Vue({
            render: h => h(communities,{
                props:{
                }
            }),
        }).$mount(element)
    });


    if (current_path !== '/admin/sources') {
        Array.from(document.getElementsByClassName('best-toggle')).forEach(function(element) {
            new Vue({
                render: h => h(bestToggle,{
                    props:{
                        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        id: element.dataset.id,
                        best: element.dataset.best !== undefined
                    }
                }),
            }).$mount(element)
        });
    }

    Array.from(document.getElementsByClassName('mobile-comment-menu')).forEach(function(element) {
        element.addEventListener("click", function(){
            let id = this.dataset.id;
            let slug = this.dataset.slug;
            let facebook_url = `https://www.facebook.com/sharer/sharer.php?u=https://meinung.de/posts/${slug}/#comment${id}`;
            let twitter_url = `https://twitter.com/share?url=https://meinung.de/posts/${slug}/#comment${id}`;
            let mnu = document.querySelector('#comment-informer');
            if (mnu !== null) {
                document.getElementById('fb-comment').href = facebook_url;
                document.getElementById('tw-comment').href = twitter_url;
                document.getElementById('report-model').value = 'Comment';
                document.getElementById('report-model-id').value = id;
                mnu.style.bottom = '0';
            }
        });
    });

    Array.from(document.getElementsByClassName('mobile-question-menu')).forEach(function(element) {
        element.addEventListener("click", function(){
            let id = this.dataset.id;
            let slug = this.dataset.slug;
            let isLogin = false;
            let subscribed = this.dataset['subscribed'] === 'yes';
            let url = `https://meinung.de/posts/${slug}/`

            if (this.dataset.user.length > 0) {
                isLogin = true
            }

            //let facebook_url = `https://www.facebook.com/sharer/sharer.php?u=https://meinung.de/posts/${slug}/`;
            //let twitter_url = `https://twitter.com/share?url=https://meinung.de/posts/${slug}/`;
            let mnu = document.querySelector('#question-informer');
            if (mnu !== null) {
                let notifToggler = mnu.querySelector('.notif-toggle-wrapper');
                let mnuClosers = mnu.querySelectorAll('.question-informer-closer');
                let shareLink = mnu.querySelector('#q-sharer');

                function shareUrl() {
                    let sharer = document.getElementById('q-sharer')
                    if (navigator.share) {
                        navigator.share({
                            url: sharer.dataset.url,
                        })
                    } else {
                        sharer.classList.add('hidden')
                    }
                }

                shareLink.addEventListener('click', shareUrl );

                //document.getElementById('fbq-comment').href = facebook_url;
                //document.getElementById('twq-comment').href = twitter_url;
                document.getElementById('report-model').value = 'Post';
                document.getElementById('report-model-id').value = id;
                document.getElementById('q-sharer').dataset['url'] = url;
                mnu.style.bottom = '0';

                if (subscribed) {
                    document.getElementById('question-informer').querySelector('input[type="checkbox"]').checked = true;
                } else {
                    document.getElementById('question-informer').querySelector('input[type="checkbox"]').checked = false;
                }

                function toggleNotifications() {
                    let checkboxEl = this.querySelector('input[type="checkbox"]');
                    let bellIcon = this.querySelector('svg.bell-icon');
                    let bellCrossedIcon = this.querySelector('svg.bell-crossed-icon');

                    if (checkboxEl.checked) {
                        bellIcon.style.display = 'none';
                        bellCrossedIcon.style.display = 'block';
                    }
                    else {
                        bellCrossedIcon.style.display = 'none';
                        bellIcon.style.display = 'block';
                    }

                    checkboxEl.checked = !checkboxEl.checked;
                    if (isLogin) {
                        fetch(`/posts/${slug}/subscribe`, {
                            method: 'POST'
                        })
                    } else {
                        showLoginInformer('');
                        checkboxEl.checked = false;
                    }
                }

                notifToggler.addEventListener('click', toggleNotifications);

                Array.from(mnuClosers).forEach(function (mnuCloser) {
                    mnuCloser.addEventListener('click', function () {
                        mnu.style.bottom = '-100%';
    
                        notifToggler.removeEventListener('click', toggleNotifications);
                        shareLink.removeEventListener('click', shareUrl );
                    }, { once: true });
                });
            }
        });
    });

    Array.from(document.getElementsByClassName('mobile-user-profile-menu')).forEach(function (element) {
        element.addEventListener('click', function () {
            let blocked = this.dataset.blocked !== undefined;
            let userId = this.dataset.userId;
            let slug = this.dataset.slug;
            let mnu = document.getElementById('user-profile-menu');

            if (mnu !== null) {
                let informerLinks = mnu.querySelector('.informer-links');
                let mnuCloser = mnu.querySelector('.informer-closer');
                
                if (blocked) {
                    informerLinks.firstElementChild.dataset.confirm = 'Möchten Sie diesen Benutzer entsperren?';
                    informerLinks.firstElementChild.textContent = 'Entsperren';
                }

                informerLinks.firstElementChild.href = `/users/${slug}/block`;
                informerLinks.lastElementChild.dataset.id = userId;

                mnu.style.bottom = '0';

                mnuCloser.addEventListener('click', function () {
                    mnu.style.bottom = '-100%';
                }, { once: true });
            }
        });
    });

    Array.from(document.getElementsByClassName('mobile-specify-question-menu')).forEach(function (element) {
        element.addEventListener('click', function () {
            let id = this.dataset.id;
            let title = this.dataset.title;
            let mnu = document.querySelector('#specify-question-informer');

            if (mnu !== null) {
                let mnuCloser = mnu.querySelector('.informer-closer');
                let formActivator = mnu.querySelector('.specify-informer__form-activator');
                let formCloser = mnu.querySelector('.comment-closer.specify-informer__btn');

                document.querySelector('.specify-informer__title').textContent = title;
                mnu.style.bottom = '0';

                function showSpecifyQuestionForm() {
                    this.style.display = 'none';
                    this.nextElementSibling.style.display = 'block';

                    formCloser.addEventListener('click', function () {
                        formActivator.nextElementSibling.style.display = 'none';
                        formActivator.style.display = 'flex';
                    }, { once: true });
                }

                formActivator.addEventListener('click', showSpecifyQuestionForm);

                mnuCloser.addEventListener('click', function () {
                    mnu.style.bottom = '-100%';

                    formActivator.removeEventListener('click', showSpecifyQuestionForm);
                }, { once: true });
            }
        });
    });

    Array.from(document.getElementsByClassName('form-submitter')).forEach(function(element) {
        element.addEventListener("click", function(){
           this.closest('form').submit();
        });
    });



});

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var swiperParams = {
    freeMode: true,
    grabCursor: true,
    breakpoints: {
        600: {
            slidesPerView: 1.8,
            spaceBetween: 20
        },
        320: {
            slidesPerView: 1.2,
            spaceBetween: 10
        }
    }
};

document.addEventListener('DOMContentLoaded', function () {
    var swiperEls = document.getElementsByClassName('base-swiper');

    for (var i = 0; i < swiperEls.length; i++) {
        swiperEls[i].querySelector('.swiper-container').classList.add("swiper-".concat(i + 1));
        swiperEls[i].querySelector('.swiper-button-prev').classList.add("btn-prev-".concat(i + 1));
        swiperEls[i].querySelector('.swiper-button-next').classList.add("btn-next-".concat(i + 1));
        new Swiper(".swiper-".concat(i + 1), _objectSpread({}, swiperParams, {
            navigation: {
                nextEl: ".btn-next-".concat(i + 1),
                prevEl: ".btn-prev-".concat(i + 1)
            }
        }));
    }
});
