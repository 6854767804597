<template>
    <div class="form-group">
        <label>Tags</label>
        <input type="hidden" name="post[raw_tags]" :value="stringTags">
        <vue-tags-input
                v-model="tag"
                :tags="tags"
                placeholder="Tags bitte hinzufügen"
                :autocomplete-items="autocompleteItems"
                :add-only-from-autocomplete="true"
                @tags-changed="update"
        />
    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import axios from 'axios';
    export default {
        name:'tags',
        components: { VueTagsInput },
        props:['current'],
        data: () => ({
            tags: [],
            tag:'',
            autocompleteItems: [],
            debounce: 300
        }),
        mounted: function() {
          // this.tags = this.currentTags
          this.tags = []
        },
        watch: {
            'tag': 'initItems',
        },
        methods: {
            update(newTags) {
                this.autocompleteItems = [];
                this.tags = newTags;
                this.$emit('get-tags', newTags);

            },
            initItems() {
                if (this.tag.length < 2) return;
                const url = `/tags/search.json?q=${this.tag}`;

                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    axios.get(url).then(response => {
                        this.autocompleteItems = response.data.results.map(a => {
                            return { text: a.name };
                        });
                    }).catch(() => console.warn('Oh. Something went wrong'));
                }, 600);
            }
        },
        computed: {
            gon: function() {
                return window.gon
            },
            currentTags() {
                return gon.raw_tags;
            },
            stringTags() {
                let arr = this.tags.map(function(tag) {
                    return tag.text;
                });
                return JSON.stringify(arr)
            }
        }
    }
</script>

<style lang="scss">
    .vue-tags-input {max-width:100%!important;}
    .ti-tag, .ti-selected-item {background-color: #2c6e83!important;}
    .ti-new-tag-input {padding: 6px 12px!important;}
    .ti-input {border-radius: 4px!important;}
</style>