<template>
    <div class="tags-list-search m-b-30">
        <div id="tags-suggestions" class="base-dropdown" v-if="tags.length > 0">
            <div v-for="(tag,index) in tags" :key="index" class="base-dropdown__item sug-tag" @click="selectTag(tag)">
                {{tag.name}}
            </div>
        </div>
        <form action="/tags" accept-charset="UTF-8" method="get">
            <input name="utf8" type="hidden" value="✓">
            <input v-debounce:600ms="loadTags" v-model="input" class="tags-list-search__input" name="str" placeholder="Suche" type="text">
            <div class="tags-list-search__button-wrapper">
                <button class="form-submitter base-icon-btn" aria-label="Submit themes search">
                    <svg height="16px" width="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                        <path d="M88.74,83.437l-23.42-23.42c-0.7-0.7-0.799-1.809-0.212-2.606c4.87-6.613,7.539-14.943,6.899-23.901C70.739,15.741,56.308,1.4,38.532,0.24C16.81-1.178-1.178,16.81,0.24,38.532C1.4,56.308,15.741,70.739,33.509,72.007c8.959,0.639,17.288-2.029,23.901-6.899c0.797-0.587,1.906-0.488,2.606,0.212l23.42,23.42c0.732,0.732,1.692,1.098,2.652,1.098s1.919-0.366,2.652-1.098C90.205,87.276,90.205,84.902,88.74,83.437z M7.661,36.131c0-15.698,12.771-28.47,28.47-28.47s28.47,12.771,28.47,28.47s-12.771,28.47-28.47,28.47S7.661,51.829,7.661,36.131z"></path>
                    </svg>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'TagSearch',
        props: ['csrf'],
        data: function () {
            return {
                tags: [],
                input: ''
            }
        },
        methods: {
            selectTag(tag) {
                this.input = tag.name
                this.tags = []
            },
            loadTags() {
                if (this.input.length > 2) {
                    let self = this;
                    let url = `/tags/search.json?q=${this.input}`;
                    fetch(url)
                        .then(response => response.json())
                        .then(function (result) {
                            self.tags = result.results
                        });
                } else {
                    this.tags = []
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    #tags-suggestions {
        border: 1px solid #D5DADD;
        top:40px;
        left:0;
        width: 100%;
        box-shadow: none;
        padding: 8px 0;
        border-radius: 4px;
    }

    .sug-tag {
        line-height: 36px;
        padding: 0 12px;
    }

</style>