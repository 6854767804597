(function() {

    document.addEventListener('click', function(event) {
        if (event.target.classList.contains('note-toggler')) {
            document.getElementsByClassName('note-toggler')[0].classList.add('hidden');
            document.getElementById('user-note').classList.remove('hidden');
            txt = document.getElementsByClassName('note-text');
            if (txt.length > 0) {
                txt[0].classList.add('hidden');
            }
        }
    }, false);

    // var el = document.getElementById("form-runner");
    // el.addEventListener("click", runQuestionForm(), false);
    //
    // function runQuestionForm(id = null) {
    //     if (window.question !== undefined) {
    //         window.question.$destroy();
    //         if (document.querySelector('#question-screen')) {
    //             document.querySelector('#question-screen').remove()
    //         }
    //     }
    //     vue_div = document.createElement('div');
    //     vue_div.id = 'question-frm';
    //     if (id !== null) {
    //         vue_div.dataset['id'] = id
    //     }
    //     document.body.appendChild(vue_div);
    //     window.run_question_form();
    // }
    //
    // document.addEventListener('DOMContentLoaded', () => {
    //     if (document.querySelector('#question-frm') !== null) {
    //         runQuestionForm()
    //     }
    // });

}).call(this);