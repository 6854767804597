(function() {

    elements = document.getElementsByClassName('stop-enter-key');

    Array.from(elements).forEach(function(element) {
        element.addEventListener('keypress', (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    });


}).call(this);