<template>
    <div class="question-themes">
        <div class="grey-button" @click.self="toggleOpened" v-bind:class="opened ? 'dark' : ''">
            <img src="/img/question-themes.png" alt="Theme icon">
            Themes
            <div v-if="opened" class="themes-window base-dropdown">
                <div class="themes-title">Themen hinzufügen</div>
                <input v-model="search_theme"  v-debounce:300ms="searchThemes" type="text" class="themes-search-input" placeholder="Suchen"/>
                <div class="themes-suggestions">
                    <div class="theme-suggestion" v-for="(suggestion, index) in suggestions" :key="index"  @click="addTheme(index)">
                        <span class="theme-name">{{suggestion.name}}</span>
                        <svg class="suggestion-control" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/><path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/></svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    const axios = require('axios');

    export default {
        name: 'themes',
        props:['csrf','current_themes'],
        data: function () {
            return {
                opened:false,
                search_theme:'',
                headers: {'X-CSRF-Token': this.csrf,'X-Requested-With' : 'XMLHttpRequest'},
                suggestions: []
            }
        },
        mounted: function() {

        },
        methods: {
            toggleOpened() {
                this.opened = !this.opened;
            },
            searchThemes() {
                if (this.search_theme.length > 0) {
                    let self = this;
                    axios.get('/tags/lookup',{params:{q:self.search_theme,w:self.current_slugs}, headers: self.headers})
                        .then(function (response) {
                            self.suggestions = response.data.themes;
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                        .finally(function () {

                        });
                } else {
                    this.suggestions = []
                }

            },
            addTheme(index) {
                //this.themes.push(this.suggestions[index]);
                this.$emit('get-theme', this.suggestions[index]);
                this.suggestions = [];
                this.search_theme = '';
                //this.opened = false;
            }
        },
        computed: {
            current_slugs: function() {
                let arr = [];
                this.current_themes.forEach(function (theme) {
                    arr.push(theme.slug);
                });
                return arr.join(',')
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import 'question.scss';

    .question-themes {
        position: relative;
    }

    .themes-search-input {
        height: 33px;
    }

    .theme-name {
        width: 155px;
    }

</style>