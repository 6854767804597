(function() {
    if (document.getElementsByClassName('control_changes').length > 0 ) {
        window.formSubmitting = false;
        window.setFormSubmitting = function() { formSubmitting = true; };

        (() => {
            const modified_inputs = new Set;
            const defaultValue = "defaultValue";
            // store default values
            addEventListener("beforeinput", (evt) => {
                const target = evt.target;
                if (!(defaultValue in target || defaultValue in target.dataset)) {
                    target.dataset[defaultValue] = ("" + (target.value || target.textContent)).trim();
                }
            });
            // detect input modifications
            addEventListener("input", (evt) => {
                const target = evt.target;
                let original;
                if (defaultValue in target) {
                    original = target[defaultValue];
                } else {
                    original = target.dataset[defaultValue];
                }
                if (original !== ("" + (target.value || target.textContent)).trim()) {
                    if (!modified_inputs.has(target) && !target.classList.contains('drop-class')) {
                        modified_inputs.add(target);
                    }
                } else if (modified_inputs.has(target)) {
                    modified_inputs.delete(target);
                }
            });
            addEventListener("beforeunload", (evt) => {
                if (window.formSubmitting) {
                    return undefined;
                }
                if (modified_inputs.size) {
                    const unsaved_changes_warning = "Changes you made may not be saved.";
                    evt.returnValue = unsaved_changes_warning;
                    return unsaved_changes_warning;
                }
            });
        })();

    }

}).call(this);


