(function() {

    document.addEventListener('DOMContentLoaded', () => {
        Array.from(document.getElementsByClassName('informer-closer')).forEach(function(element) {
            element.addEventListener("click", function(){
                let parent = element.closest('.bottom-informer');
                parent.style.bottom = '-100%';
            });
        });
    });

    document.addEventListener('click', function(event) {
        let target = event.target;
        if (document.body.id === 'mobile-body' && target.classList.contains('informer') || target.closest('.informer') !== null ) {
            if (target.closest('.informer') !== null) {
                var el = target.closest('.informer');
            } else {
                var el = target;
            }

            if (el.dataset['txt'] !== undefined) {
                var txt = el.dataset['txt']
            } else {
                var txt = ''
            }

            if (el.dataset['informer'] === 'login') {
                showLoginInformer(txt);
            }

        }

    });

}).call(this);